@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .link,
  .post a {
    @apply text-blue-400;
    @apply underline;
    @apply font-semibold;
  }

  .link:hover,
  .post a:hover {
    @apply text-blue-200;
  }

  .post blockquote {
    @apply text-2xl;
    @apply font-semibold;
    @apply leading-snug;
  }

  .post blockquote p::before {
    content: '"';
  }

  .post blockquote p::after {
    content: '"';
  }

  .post h1,
  .report h1 {
    @apply text-3xl;
    @apply font-bold;
  }

  .post h2,
  .report h2 {
    @apply text-2xl;
    @apply font-bold;
  }

  .post h3,
  .report h3 {
    @apply text-xl;
    @apply font-bold;
  }

  .post img {
    @apply rounded-lg;
    @apply my-8;
  }

  .post ol {
    @apply list-decimal;
    @apply ml-10;
    @apply mb-4;
  }

  .post ul {
    @apply list-disc;
    @apply pl-10;
    @apply mb-4;
  }

  .report h1 {
    @apply mb-4 mt-8;
  }

  .report h2 {
    @apply mb-4 mt-8;
  }

  .report h3 {
    @apply mb-2 mt-4;
  }

  .report__max-w-base {
    @apply max-w-[100ch];
  }

  .report__mt-base {
    @apply mt-4;
  }

  .report__dangerous-html {
    @apply post report__max-w-base whitespace-pre-wrap text-lg leading-relaxed tracking-tight text-gray-100 lg:tracking-normal;
  }

  .pages > * {
    @apply max-w-[110ch] text-xl leading-snug tracking-tighter text-gray-200 lg:leading-normal lg:tracking-normal;
  }

  .pages h1 {
    @apply mb-10 w-fit self-center rounded-md bg-blue px-6 py-2 text-center text-4xl font-bold text-blue-200;
  }

  .pages h2 {
    @apply mb-6 w-full text-center text-4xl font-semibold leading-none text-white;
  }

  .pages h3 {
    @apply mb-2 w-fit rounded-sm bg-blue px-2 text-xl font-bold text-blue-50;
  }

  .pages h3:not(:first-child) {
    @apply mt-6;
  }

  .pages p:not(:last-child) {
    @apply mb-5;
  }

  .pages img {
    @apply rounded-lg;
  }

  .pages ul {
    @apply mb-4 list-disc pl-4;
  }

  .grid-block img {
    @apply max-w-full;
  }

  .button {
    @apply flex items-center justify-center rounded-md bg-green p-3 text-lg font-semibold text-green-950;
    @apply transition-colors duration-200 ease-in-out;
    @apply hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-900 disabled:cursor-not-allowed disabled:bg-gray-800 disabled:text-gray-400;
    @apply w-fit whitespace-nowrap px-16;
  }

  .button-blue {
    @apply button;
    @apply bg-blue text-gray-200 hover:bg-blue-800 hover:text-gray-100 focus:ring-blue-900;
  }

  .button-gray {
    @apply button;
    @apply bg-gray-800 text-gray-300 hover:bg-gray-700 hover:text-gray-100 focus:ring-gray-600;
  }
}

.logo {
  @apply flex items-center justify-center rounded-lg bg-gray-800;
}

.logo__img {
  @apply object-contain p-5 sm:p-8;
}

@layer utilities {
  .align-last-baseline {
    align-items: last baseline;
  }
}
